body {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently */
}
.bd-placeholder-img {
    font-size: 1.125rem;
    text-anchor: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media (min-width: 768px) {
    .bd-placeholder-img-lg {
        font-size: 3.5rem;
    }
}
.container {
    max-width: 960px;
}
.lh-condensed { line-height: 1.25; }
.card-columns {
    column-count: 2;
}
.card {
    cursor: pointer;
}
.btn-outline-primary {
    color: #000000;
    border-color: #000000;
}
.btn-outline-primary:hover {
    color: #fff;
    background-color: #000000;
    border-color: #000000;
}
label {
    font-size: 0.9rem;
}
p {
    word-break: break-word;
}
.lead {
    font-size: 1rem;
    font-weight: 300;
    margin-bottom: 0;
}
.invalid + .invalid-feedback {
    display: block;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #000000;
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled:hover {
    background-color: #fff;
}
#payment-summary p.lead {
    border-bottom: 1px solid #aaaaaa;
}
.invalid-feedback {
    display: block !important;
}
.dropdown {
    flex: 1 1 auto;
    width: 1%;
}